import React from 'react';
import { flowRight } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import {
  SECTION_MY_POSTS,
  getLayoutName,
  POST_ACTION_EDIT,
  POST_ACTION_DELETE,
  POST_ACTIONS,
  POST_ACTION_SUBSCRIBE,
  POST_ACTION_SHARE,
  POST_ACTION_TRANSLATE,
  POST_ACTION_CHANGE_LANGUAGE,
  getTPASettignsLiveSiteEditingDeprecated,
} from '@wix/communities-blog-client-common';
import AnimatedLoader from '../../../common/components/animated-loader';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { connect } from '../../../common/components/runtime-context';
import Pagination from '../../../common/containers/pagination/pagination';
import withPermissions from '../../../common/hoc/with-permissions';
import { getCanSeeMoreButton } from '../../../common/selectors/app-settings-selectors';
import { getLayoutType } from '../../../common/selectors/layout-selectors';
import { getMyPosts } from '../../../common/selectors/post-selectors';
import { getIsUserGuestWriter } from '../../../common/selectors/user-role-selectors';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getPostCount } from '../../../common/store/post-count/post-count-selector';
import {
  MY_POSTS_PAGE_SIZE,
  MY_POSTS_PAGE_TAB_PUBLICATIONS,
  MY_POSTS_PAGE_TAB_DRAFTS,
  MY_POSTS_PAGE_TAB_SUBMISSIONS,
} from '../../constants/my-posts-page';
import { getActiveTab } from '../../store/active-tab/active-tab-selectors';
import { MY_POSTS_ENTITY } from '../../store/initial-data/fetch-initial-data';
import NoPosts from './no-posts';
import styles from './my-posts.scss';

const getVisibleActions = memoizeOne(
  (activeTab, isUserGuestWriter, isLiveSiteEditingDeprecated) => {
    if (isLiveSiteEditingDeprecated) {
      return {
        [MY_POSTS_PAGE_TAB_PUBLICATIONS]: isUserGuestWriter
          ? [
              POST_ACTION_SUBSCRIBE,
              POST_ACTION_SHARE,
              POST_ACTION_TRANSLATE,
              POST_ACTION_CHANGE_LANGUAGE,
            ]
          : POST_ACTIONS,
        [MY_POSTS_PAGE_TAB_DRAFTS]: [],
        [MY_POSTS_PAGE_TAB_SUBMISSIONS]: [],
      }[activeTab];
    }
    return {
      [MY_POSTS_PAGE_TAB_PUBLICATIONS]: isUserGuestWriter
        ? [
            POST_ACTION_SUBSCRIBE,
            POST_ACTION_SHARE,
            POST_ACTION_TRANSLATE,
            POST_ACTION_CHANGE_LANGUAGE,
          ]
        : POST_ACTIONS,
      [MY_POSTS_PAGE_TAB_DRAFTS]: [POST_ACTION_EDIT, POST_ACTION_DELETE],
      [MY_POSTS_PAGE_TAB_SUBMISSIONS]: [POST_ACTION_EDIT, POST_ACTION_DELETE],
    }[activeTab];
  },
);
class MyPosts extends React.Component {
  state = { page: 1 };

  handlePageClick = ({ page }) => {
    this.setState({ page });
    this.props.fetchPageData(page);
  };

  render() {
    const {
      posts,
      totalPostCount,
      componentId,
      canSeeMoreButton,
      isLoading,
      layoutType,
      activeTab,
      isUserGuestWriter,
      isLiveSiteEditingDeprecated,
    } = this.props;
    const hasPosts = Boolean(posts.length);

    return (
      <AnimatedLoader isLoading={isLoading}>
        {hasPosts ? (
          <>
            <PostListProGallery
              layoutName={getLayoutName(layoutType)}
              layoutType={layoutType}
              section={SECTION_MY_POSTS}
              domId={componentId}
              allPosts={posts}
              canSeeMoreButton={canSeeMoreButton}
              visibleActions={getVisibleActions(
                activeTab,
                isUserGuestWriter,
                isLiveSiteEditingDeprecated,
              )}
              isLoading={isLoading}
            />
            <Pagination
              dataHook="my-posts-pagination-container"
              useDescriptionColor={true}
              page={this.state.page}
              onChange={this.handlePageClick}
              pageSize={MY_POSTS_PAGE_SIZE}
              section={SECTION_MY_POSTS}
              entityCount={totalPostCount}
              createPageUrl={() => {}}
              containerClassName={styles.paginationContainer}
            />
          </>
        ) : (
          <NoPosts />
        )}
      </AnimatedLoader>
    );
  }
}

MyPosts.propTypes = {
  totalPostCount: PropTypes.number.isRequired,
  posts: PropTypes.array.isRequired,
  componentId: PropTypes.string.isRequired,
  fetchPageData: PropTypes.func.isRequired,
  canSeeMoreButton: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  layoutType: PropTypes.number.isRequired,
  activeTab: PropTypes.string.isRequired,
  isUserGuestWriter: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  totalPostCount: getPostCount(state),
  posts: getMyPosts(state),
  componentId: host.id,
  fetchPageData: actions.fetchMyPostsPageDataPromisified,
  canSeeMoreButton: getCanSeeMoreButton(state, ownProps.canSee),
  isLoading: getIsEntityLoading(state, MY_POSTS_ENTITY),
  layoutType: getLayoutType(state, SECTION_MY_POSTS),
  activeTab: getActiveTab(state),
  isUserGuestWriter: getIsUserGuestWriter(state),
  isLiveSiteEditingDeprecated: getTPASettignsLiveSiteEditingDeprecated(state),
});

export default flowRight(withPermissions, connect(mapRuntimeToProps))(MyPosts);
