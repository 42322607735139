import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { withTranslation } from '@wix/yoshi-flow-editor';
import App from '../../../common/components/app';
import PermissionsProvider from '../../../common/components/permissions-provider';
import PopoverRoot from '../../../common/components/popovers/popover-root';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { connect } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getIsCreatedWithResponsiveEditor } from '../../../common/selectors/app-settings-selectors';
import { isInWix } from '../../../common/services/is-in-wix';
import {
  getLanguage,
  isEditor,
  getIsMobile,
  getIsRTL,
  isSeo as getIsSeo,
  getIsRendered,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import MessageRoot from '../messages/message-root';
import ModalRoot from '../modals/modal-root';
import MyPosts from '../my-posts';
import MyPostsFrame from '../my-posts-frame';
import styles from './app-root.scss';

const MyPostsPage = () => (
  <MyPostsFrame>
    <MyPosts />
  </MyPostsFrame>
);

class AppRoot extends React.Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
    if (this.props.isEditor && this.props.createdWithResponsiveEditor) {
      document.body.style.height = 'auto';
    }
  }

  render() {
    const { isMobile, isRtl, isSeo, isRendered, i18n, translations, language } = this.props;
    i18n.addResourceBundle(language, 'translation', translations, true, false);

    if (!isRendered) {
      return null;
    }

    return (
      <ResponsiveListener
        dataHook="my-posts-page-root"
        className={classNames(styles.baseStyles, styles.root)}
      >
        <PermissionsProvider>
          <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRtl, seo: isSeo }}>
            <App
              main={<MyPostsPage />}
              modalRoot={<ModalRoot />}
              messageRoot={<MessageRoot />}
              popoverRoot={<PopoverRoot />}
            />
          </TPAComponentsProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.array,
  isEditor: PropTypes.bool,
  isMobile: PropTypes.bool,
  isRtl: PropTypes.bool,
  isSeo: PropTypes.bool,
  createdWithResponsiveEditor: PropTypes.bool,
  isRendered: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isEditor: isEditor(state),
  isMobile: getIsMobile(state),
  isRtl: getIsRTL(state),
  isSeo: getIsSeo(state),
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  isRendered: getIsRendered(state),
});

export default flowRight(withTranslation(), withReduxStore, connect(mapRuntimeToProps))(AppRoot);
