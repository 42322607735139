import { BLOG_HEADER_TOTAL_RESULTS } from '@wix/communities-blog-client-common';

export const getTotalResults = (headers: Headers | Record<string, string>) => {
  const headerName = BLOG_HEADER_TOTAL_RESULTS.toLowerCase();
  const totalResults =
    typeof headers.get === 'function'
      ? (headers as Headers).get(headerName) ?? '0'
      : (headers as Record<string, string>)[headerName];

  return parseInt(totalResults, 10);
};
